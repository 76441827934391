<template>
  <div>
    <!-- Start Header Area -->
    <Header class="digital-agency-header color-white">
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <HomeHero />
    <!-- End Slider Area -->

    <!-- Start About Area  -->
    <div class="about-area rn-section-gap bg_color--7">
      <div class="about-wrapper">
        <HomeIntro>
          <img
            slot="image"
            src="../assets/images/home/about-people.png"
            alt="About Images"
          />

          <div slot="main-content" class="section-title">
            <h3 class="heading-title text-white text-italic mb-7">
              BeMagnified Media and Marketing
            </h3>
            <p class="description text-white">
              We are more than just a media and marketing agency.<br />
              We are a team of visionaries, strategists, and innovators
              dedicated to magnifying the potential of your business.<br />Our
              mission is simple yet ambitious: to elevate your brand and
              maximize your success in an ever-evolving digital landscape.
            </p>
            <router-link
              class="rn-button-style--2 btn_solid mt--30"
              to="/about"
              slot="about-extra"
            >
              Learn More
            </router-link>
          </div>
        </HomeIntro>
      </div>
    </div>

    <WayWeHelpGrow />

    <!-- Start Portfolio Area  -->
    <div class="rn-portfolio-area rn-section-gap bg_color--1">
      <div class="portfolio-sacousel-inner pb--30">
        <div class="container">
          <div class="row">
            <v-col lg="12">
              <div class="section-title text-center mb--20 mb_sm--0 mb_md--0">
                <h2 class="heading-title">recent projects.</h2>
                <p>Here are some of our latest creations.</p>
              </div>
            </v-col>
          </div>
        </div>
        <Portfolio />
      </div>
    </div>
    <!-- Start Portfolio Area  -->

    <YourVisionOurMission>
      <div class="section-title" slot="main-content">
        <h2>Your Vision is Our Mission.</h2>
        <h3 class="subtitle text-normal text-italic mt-3">
          We work with you every step of the way.
        </h3>
      </div>

      <img
        slot="image"
        class="about-img w-100"
        src="../assets/images/home/mission-people.png"
        alt="About Image"
      />
    </YourVisionOurMission>

    <!-- Start Testimonial Area  -->
    <!-- <div class="rn-testimonial-area rn-section-gap">
      <v-container>
        <Testimonial />
      </v-container>
    </div> -->
    <!-- Start Testimonial Area  -->

    <!-- Start Call to action Area  -->
    <CallToAction />
    <!-- End Call to action Area  -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
import Header from "../components/header/Header";
import HomeHero from "../components/slider/HomeHero";
import HomeIntro from "../components/about/HomeIntro.vue";
import WayWeHelpGrow from "../components/service/WayWeHelpGrow";
import Portfolio from "../components/portfolio/PortfolioHomepage";
import YourVisionOurMission from "../components/about/YourVisionOurMission";
import Testimonial from "../components/testimonial/Testimonial";
import CallToAction from "../components/callto-action/CallToAction.vue";
import Footer from "../components/footer/FooterTwo";

export default {
  components: {
    Header,
    HomeHero,
    HomeIntro,
    WayWeHelpGrow,
    Portfolio,
    YourVisionOurMission,
    Testimonial,
    CallToAction,
    Footer
  },
  data() {
    return {};
  },
  mounted() {
    let liveChatScript = document.createElement("script");
    liveChatScript.setAttribute("src", "//js-na1.hs-scripts.com/22756266.js");
    document.head.appendChild(liveChatScript);
  }
};
</script>

<style lang="scss">
.bg-white--service {
  .single-service.service__style--2.bg-color-gray a {
    background: #fff;
    text-align: center;
  }
}
</style>
