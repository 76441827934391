<template>
  <div>
    <!-- Start Portfolio Activation  -->
    <VueSlickCarousel
      v-bind="settings"
      class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40"
    >
      <!-- Start Single Portfolio  -->
      <div class="portfolio" v-for="(project, i) in portfolioContent" :key="i">
        <div class="thumbnail-inner">
          <v-img class="thumbnail" :src="require('../../assets/images/portfolio/' + project.key + '/1.jpg')"></v-img>
          <v-img class="bg-blr-image" :src="require('../../assets/images/portfolio/' + project.key + '/1.jpg')"></v-img>
        </div>
        <div class="content">
          <div class="inner">
            <p>{{ project.projectType }}</p>
            <h4>
              <router-link :to="'/portfolio/' + project.key">{{ project.name }}</router-link>
            </h4>
            <div class="portfolio-button">
              <router-link class="rn-btn" :to="'/portfolio/' + project.key"
                >Learn More</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- End Single Portfolio  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import data from "../../data/portfolio.json";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      portfolioContent: data.projects,
      // for all works
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,

        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1263,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 599,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      }
    };
  }
};
</script>
