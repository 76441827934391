<template>
  <div class="rn-service-area rn-section-gap bg_color--1">
    <v-container>
      <v-row>
        <v-col lg="5" sm="12" cols="12">
          <div
            class="section-title text-left mt--30 mt_md--5 mt_mobile--5 mb_mobile--10"
          >
            <h2 class="heading-title">Ways we help you grow</h2>
            <p class="mt--30">
              At BeMagnified our goal is to help you establish a serious online
              presence through a variety of ways.
            </p>
            <div class="service-btn">
              <router-link
                class="rn-button-style--2 btn_solid rn-btn-small"
                to="/services"
                slot="about-extra"
              >
                Learn More
              </router-link>
            </div>
            <div class="service-btn">
              <router-link to="/contact">
                <a class="btn-transparent rn-btn-dark" href="/contact"
                  ><span class="text">Request Custom Service</span></a
                >
              </router-link>
            </div>
            <!-- <img class="mt--40" src="../../assets/images/home/home-services.png" /> -->
          </div>
        </v-col>
        <v-col lg="7" sm="12" cols="12" class="mt_md--50">
          <!-- Start Service Area  -->
          <v-row class="service-one-wrapper">
            <!-- Start Single Service  -->
            <v-col
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="(service, i) in serviceContent"
              :key="i"
            >
              <div class="single-service service__style--4">
                <router-link to="/services">
                  <div class="service">
                    <div class="icon" v-html="iconSvg(service.icon)"></div>
                    <div class="content">
                      <h3 class="heading-title">{{ service.title }}</h3>
                      <p>
                        {{ service.desc }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </v-col>
            <!-- End Single Service  -->
          </v-row>
          <!-- Start Service Area  -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          icon: "users",
          title: "Branding + Marketing",
          desc: `Let us handle your brand and watch your business grow.`
        },
        {
          icon: "aperture",
          title: "Photo + Video",
          desc: `Want to show off your business on camera? Let us do that for you.`
        },
        {
          icon: "pen-tool",
          title: "Digital + Graphic Design",
          desc: `Not sure how to represent your brand? Let us help.`
        },
        {
          icon: "layers",
          title: "Web Design + Development",
          desc: `From a simple website to a web application, we got you covered.`
        }
      ]
    };
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
  }
};
</script>
