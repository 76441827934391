<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="rn-testimonial-content">
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in testimonialContent" :key="item.id">
            <v-card flat v-for="(item, i) in item.content" :key="i">
              <v-card-text>
                <div class="inner">
                  <p>
                    {{ item.description }}
                  </p>
                </div>
                <div class="author-info">
                  <h6>
                    <span>{{ item.name }}</span> -
                    {{ item.designation }}
                  </h6>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- End .v-tabs-items -->
        <v-tabs v-model="tab" centered hide-slider center-active>
          <v-tab v-for="item in tabItems" :key="item.id">
            <div class="thumb">
              <img :src="item.src" alt="testimonial image" />
            </div>
          </v-tab>
        </v-tabs>
        <!-- End .v-tabs -->
      </div>
      <!-- End .rn-testimonial-content -->
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        tab: null,
        testimonialContent: [
          {
            id: 1,
            content: [
              {
                name: "Andrey C.",
                description:
                  "I’m so glad we finally found a designer who understands customer ideas!",
                designation: "Best Future Homes",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                name: "Vincent D.",
                description:
                  "We loved using BeMagnified for a music video that we wanted to do! They were very professional and everything was delivered on time. We really appreciated their hard work and will use them again for sure!",
                designation: "Church/Musician",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                name: "Paul L.",
                description:
                  "Went from nothing to having a fully functioning website within a week for a VERY reasonable price.",
                designation: "Musician",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                name: "Oleg L.",
                description:
                  "I thought would be hard to find a logo I liked, but I was presented with a few designs that I absolutely love and use for my business.",
                designation: "Helping Hand Services",
              },
            ],
          },
          // {
          //   id: 4,
          //   content: [
          //     {
          //       name: "JOHNS DUE ",
          //       description:
          //         "Standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original since the 1500s.",
          //       designation: " COO, AMERIMAR ENTERPRISES, INC.",
          //     },
          //   ],
          // },
        ],

        tabItems: [
          {
            id: 1,
            src: "https://ui-avatars.com/api/?name=Andrey+C",
          },
          {
            id: 2,
            src: "https://ui-avatars.com/api/?name=V+D",
          },
          {
            id: 3,
            src: "https://ui-avatars.com/api/?name=P+L",
          },
          {
            id: 4,
            src: "https://ui-avatars.com/api/?name=O+L",
          },
          // {
          //   id: 4,
          //   src: "https://ui-avatars.com/api/?name=F+H",
          // },
        ],
      };
    },
  };
</script>
