import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import Missing from "../views/404.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "BeMagnified",
    },
  },
  {
    path: "/rebrand",
    meta: {
      title: "BeMagnified",
    },
    component: () => import("../views/Landing.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About | BeMagnified",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/services",
    name: "Services",
    meta: {
      title: "Services | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/development",
    name: "Development",
    meta: {
      title: "Service | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/design",
    name: "Design",
    meta: {
      title: "Service | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/marketing",
    name: "Marketing",
    meta: {
      title: "Marketing | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/services/photovideo",
    name: "PhotoVideo",
    meta: {
      title: "Photo/Video | BeMagnified",
    },
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    meta: {
      title: "Portfolio | BeMagnified",
    },
    component: () => import("../views/portfolio/Portfolio.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: {
      title: "Contact | BeMagnified",
    },
    component: () => import("../views/Contact.vue"),
  },

  // portfolio pages
  {
    path: '/portfolio/:projectName',
    component: () => import('../views/portfolio/project/Index.vue'),
    props: true,
    meta: {
      title: "Portfolio | BeMagnified",
    },
  },
  
  {
    path: "*",
    component: Missing,
    meta: {
      title: "404 | BeMagnified",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
