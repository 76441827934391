<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div
      class="slide slide-style-1 slider-fixed--height d-flex align-center backdrop"
      data-black-overlay="6"
    >
      <div class="overlay"></div>
      <video autoplay muted loop class="bg-vid">
        <source src="../../assets/images/home/bg.mp4" type="video/mp4" />
      </video>
      <v-container class="content position-relative">
        <v-row>
          <v-col cols="10">
            <div class="inner">
              <div class="hero-text">
                <h1 class="heading-title theme-gradient text-right">
                  Elevating Your Brand, <br />
                  Maximizing Your Success.
                </h1>
                <img
                  class="brand-symbol d-none d-lg-block"
                  src="../../assets/images/logo/symbol.png"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
        <!-- End .row -->

        <!-- Start Service Area  -->
        <!-- <div class="service-wrapper service-white">
          <ServiceOne />
        </div> -->
        <!-- End Service Area  -->
      </v-container>
      <div class="branding content d-none d-lg-block">
        <h4 class="hero-subtitle">
          Branding and Creative | Digitial and Web Design | Advertising and
          Content
        </h4>
      </div>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import ServiceOne from "../../components/service/ServiceOne";
export default {
  components: {
    ServiceOne
  }
};
</script>

<style scoped lang="scss">
.backdrop {
  background-image: url(../../assets/images/bg/bg-hero-home.jpg);
  overflow: hidden;
  background-size: cover;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #111;
  opacity: 0.5;
  z-index: 2;
}

.bg-vid {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  object-fit: fill
}

.content {
  z-index: 3;
}

.hero-subtitle {
  color: white;
  font-weight: 400;
  font-size: 25px;
  text-align: center;
}

.brand-symbol {
  position: absolute;
  right: -110px;
  bottom: 25px;
}

.branding {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;

  h4 {
    font-weight: 500;
  }
}
</style>
